body {
  background-color: gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.signContainer {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  height: 250px;
  margin: 0 auto;
  border: 1px solid black;
  margin-top: 4px;
  position: relative;
}

.sigCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sigPad {
  width: 80%;
  height: 100%;
}

.buttons {
  height: 30px;
}

.sigImage {
  background-size: 200px 200px;
  width: 200px;
  height: 200px;
  background-color: white;
}

.document-icon {
  width: 2.5rem;
}